import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Seo from "gatsby-plugin-wpgraphql-seo";
import MenuBar from "../partials/global/MenuBar";
import BackgroundMedia from "../partials/misc/BackgroundMedia";
import BackgroundImage from "../components/elements/BackgroundImage";
import HomeHeader from "../partials/pages/HomeHeader";
import HomeRestaurantsHeader from "../partials/pages/HomeRestaurantsHeader";
import RestaurantsList from "../partials/restaurant/RestaurantsList";
import PageContent from "../partials/sections/PageContent";
function Home(props) {
  const {
    data: {
      homePage,
      restaurants: { nodes: restaurants },
      homePlaceholderBG: placeholder,
    },
  } = props;
  const {
    template: {
      homePageFields: { background, sections },
    },
  } = homePage;

  const renderBackground = () => {
    if (background && background.length) {
      return <BackgroundMedia background={background} />;
    }
    return <BackgroundImage image={placeholder} alt="Ramen Kagura" />;
  };

  return (
    <>
      <Seo post={homePage} />
      <section className="full-height-section">
        <div className="overflow-hidden flex-grow-1">
          <HomeHeader />
          {renderBackground()}
        </div>
      </section>
      <PageContent pageSections={sections} />
      <section className="full-height-section" id="restaurants">
        <MenuBar />
        <div className="overflow-hidden flex-grow-1">
          <HomeRestaurantsHeader />
          <RestaurantsList restaurants={restaurants} />
        </div>
      </section>
    </>
  );
}

export const query = graphql`
  query ($languageCode: WpLanguageCodeEnum, $pageId: String) {
    homePage: wpPage(id: { eq: $pageId }) {
      seo {
        ...YoastPageSEOContent
      }
      template {
        ... on WpTemplate_Home {
          homePageFields {
            background {
              ... on WpTemplate_Home_Homepagefields_Background_Image {
                fieldGroupName
                backgroundImage {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
              ... on WpTemplate_Home_Homepagefields_Background_Video {
                backgroundVideoUrl
                fieldGroupName
              }
            }
            sections {
              ... on WpTemplate_Home_Homepagefields_Sections_FeaturedItems {
                fieldGroupName
                heading
                items {
                  title
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          placeholder: NONE
                          sizes: "(min-width: 992px) calc((90vw - 192) / 3), (min-width: 768px) calc((100vw - 144px) / 3), (min-width: 576px) calc((100vw - 64px) / 2), calc(100vw - 32px)"
                        )
                      }
                    }
                  }
                  video {
                    localFile {
                      publicURL
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    restaurants: allWpRestaurant(
      filter: { language: { code: { eq: $languageCode } } }
      sort: { fields: termOrder, order: ASC }
    ) {
      nodes {
        id
        name
        slug
        language {
          name
          code
          slug
          locale
        }
        restaurantDetails {
          featuredImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  aspectRatio: 1
                  placeholder: NONE
                  sizes: "(min-width: 992px) calc((90vw - 192px) / 5), (min-width: 768px) calc((100vw - 128px)/3),calc(100vw - 96px / 2)"
                )
              }
            }
          }
          cocktails
          terraza
          wifi
          wheelchair
        }
      }
    }

    homePlaceholderBG: file(relativePath: { eq: "placeholders/home-bg.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

Home.propTypes = {
  data: PropTypes.shape({
    homePage: PropTypes.shape({
      template: PropTypes.shape({
        homePageFields: PropTypes.shape({
          background: PropTypes.arrayOf(PropTypes.shape({})),
          sections: PropTypes.arrayOf(PropTypes.shape({})),
        }).isRequired,
      }).isRequired,
    }).isRequired,
    restaurants: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
    homePlaceholderBG: PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default Home;
