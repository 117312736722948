import React from "react";
import CurveHeading from "../../components/svgs/CurveHeading";
import HeadingTriangle from "../../components/svgs/HeadingTriangle";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as styles from "./FeaturedItems.module.scss";
function FeaturedItems(props) {
  const {
    data: { heading, items },
  } = props;

  const renderHeading = () => {
    if (!heading) return null;
    return (
      <div className={styles.headingWrap}>
        <CurveHeading className={styles.headingCurveLeft} />
        <h4>
          {heading}
          <div className={styles.triangleWrap}>
            <HeadingTriangle className={styles.headingTriangle} />
          </div>
        </h4>
        <CurveHeading className={styles.headingCurveRight} />
      </div>
    );
  };
  const renderItemMedia = (item) => {
    if (!item.image?.localFile && !item.video) return null;
    if (item.image) {
      return (
        <div className={styles.itemMedia}>
          <GatsbyImage
            // className={styles.itemImage}
            image={getImage(item.image.localFile)}
            alt={item.image.altText}
          />
        </div>
      );
    } else if (item.video?.localFile?.publicURL) {
      return (
        <div className={styles.itemMedia}>
          <video
            className={styles.itemVideo}
            src={item.video.localFile.publicURL}
            playsInline
            muted
            autoPlay
            loop
          ></video>
        </div>
      );
    }
  };

  const renderItems = () => {
    if (!(items && items.length)) return null;
    return (
      <div className={styles.featuredItemsWrap}>
        <div className={styles.featuredItemsContainer}>
          <div className={styles.featuredItemsRow}>
            {items.map((item, index) => {
              return (
                <div
                  className={styles.featuredItem}
                  key={`featured-item-${index}`}
                >
                  <p class={styles.itemTitle}>{item.title}</p>
                  {renderItemMedia(item)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <section className={styles.featuredItemsSection}>
      <div className={styles.featuredItems}>
        {renderHeading()}
        {renderItems()}
      </div>
    </section>
  );
}
export default FeaturedItems;
