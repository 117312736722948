import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 7"
    preserveAspectRatio="xMaxYMax slice"
    {...props}
  >
    <path d="M2 0h24c2 0 1 1-1 2l-7 3c-4 2-4 2-8 0L3 2C1 1 0 0 2 0" />
  </svg>
);
export default SVGComponent;
