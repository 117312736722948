import * as React from "react";
const CurveHeading = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    viewBox="0 0 28 9"
    {...props}
  >
    <path d="M0 9c2 0 7-1 13-4s8-5 15-5v9H0" />
  </svg>
);
export default CurveHeading;
