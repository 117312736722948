import React, { useContext } from "react";
import { useStaticQuery, graphql, Link, navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as styles from "./HomeHeader.module.scss";
import MenuContext from "../../context/menu-context";

function HomeHeader() {
  const data = useStaticQuery(graphql`
    query {
      headerLogo: file(
        relativePath: { eq: "branding/kagura-header-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 480, placeholder: NONE)
        }
      }
      homeText: file(relativePath: { eq: "pages/home-text.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      homeTextMobile: file(relativePath: { eq: "pages/home-text-mobile.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      homeLeftMenuEN: wpMenu(locations: { eq: PRIMARY_NAV_LEFT___EN }) {
        id
        menuItems {
          nodes {
            id
            label
            url
            path
          }
        }
      }
      homeLeftMenuES: wpMenu(locations: { eq: PRIMARY_NAV_LEFT }) {
        id
        menuItems {
          nodes {
            id
            label
            url
            path
          }
        }
      }
      homeRightMenuEN: wpMenu(locations: { eq: PRIMARY_NAV_RIGHT___EN }) {
        id
        menuItems {
          nodes {
            id
            label
            url
            path
          }
        }
      }
      homeRightMenuES: wpMenu(locations: { eq: PRIMARY_NAV_RIGHT }) {
        id
        menuItems {
          nodes {
            id
            label
            url
            path
          }
        }
      }
    }
  `);
  const {
    headerLogo,
    homeText,
    homeTextMobile,
    homeLeftMenuEN,
    homeLeftMenuES,
    homeRightMenuEN,
    homeRightMenuES,
  } = data;
  const ctx = useContext(MenuContext);

  const { languages, currentLangCode, switchLanguage } = ctx;

  const renderLangChangeButton = () => {
    if (languages && languages.length && currentLangCode) {
      return (
        <>
          {languages.map((item) => {
            if (currentLangCode !== item.code.toLowerCase()) {
              return (
                <li className={styles.menuItem} key={item.code}>
                  <button
                    className={[
                      styles.langSwitcher,
                      styles[item.code.toLowerCase()],
                    ].join(" ")}
                    type="button"
                    onClick={() => {
                      switchLanguage(item.code.toLowerCase());
                      navigate(item.homeUrl);
                    }}
                  >
                    {item.name}
                  </button>
                </li>
              );
            }
            return null;
          })}
        </>
      );
    }
    return null;
  };

  const renderLeftMenu = () => {
    const { menuItems } =
      ctx.currentLangCode === "en" ? homeLeftMenuEN : homeLeftMenuES;
    const { nodes: menuItemsArr } = menuItems || { nodes: [] };

    if (!menuItemsArr.length) {
      return null;
    }

    return (
      <ul className={[styles.menuList, "justify-content-end"].join(" ")}>
        {menuItemsArr.map((menuItem) => (
          <li className={styles.menuItem} key={menuItem.id}>
            <Link className={styles.menuLink} to={menuItem.path}>
              {menuItem.label}
            </Link>
          </li>
        ))}
      </ul>
    );
  };

  const renderRightMenu = () => {
    const { menuItems } =
      ctx.currentLangCode === "en" ? homeRightMenuEN : homeRightMenuES;
    const { nodes: menuItemsArr } = menuItems || { nodes: [] };

    if (!menuItemsArr.length) {
      return null;
    }

    return (
      <ul className={[styles.menuList, "justify-content-start"].join(" ")}>
        {menuItemsArr.map((menuItem) => (
          <li className={styles.menuItem} key={menuItem.id}>
            <Link className={styles.menuLink} to={menuItem.path}>
              {menuItem.label}
            </Link>
          </li>
        ))}
        {renderLangChangeButton()}
      </ul>
    );
  };

  const renderMobileMenu = () => {
    const {
      mobileNav: { menuItems },
    } = ctx;
    const { nodes: menuItemsArr } = menuItems || { nodes: [] };

    if (!menuItemsArr.length) {
      return null;
    }

    return (
      <ul className={styles.mobileMenuList}>
        {menuItemsArr.map((menuItem) => (
          <li className={styles.menuItem} key={menuItem.id}>
            <Link className={styles.menuLink} to={menuItem.path}>
              {menuItem.label}
            </Link>
          </li>
        ))}
        {/* {renderLangChangeButton()} */}
      </ul>
    );
  };

  return (
    <div className={[styles.introContainer, "container-fluid"].join(" ")}>
      <div className="row h-100">
        <div
          className={[
            styles.leftCol,
            "col position-relative flex-grow-1 d-none d-xl-flex px-4 align-items-center justify-content-end pt-xl-5",
          ].join(" ")}
        >
          {renderLeftMenu()}
        </div>
        <div className={[styles.centerCol, "col col-12 col-xl-auto"].join(" ")}>
          <div className="d-flex flex-column h-100 justify-content-between align-items-center">
            <div className={styles.headerLogoContainer}>
              <GatsbyImage
                className={styles.headerLogo}
                image={getImage(headerLogo)}
                loading="eager"
                alt="Ramen Kagura"
              />
              <div className={styles.mobileLangSwitcher}>
                <ul>{renderLangChangeButton()}</ul>
              </div>
            </div>
            <div className={styles.textImageContainer}>
              <GatsbyImage
                className={[styles.homeTextImage, "d-md-none"].join(" ")}
                image={getImage(homeTextMobile)}
                loading="eager"
                alt="Ponte Cerdx"
              />
              <GatsbyImage
                className={[styles.homeTextImage, "d-none d-md-block"].join(
                  " "
                )}
                image={getImage(homeText)}
                loading="eager"
                alt="Ponte Cerdx"
              />
            </div>
            <div
              className={[
                styles.mobileMenuContainer,
                "d-xl-none mt-5 mb-4",
              ].join(" ")}
            >
              {renderMobileMenu()}
            </div>
          </div>
        </div>
        <div
          className={[
            styles.rightCol,
            "col position-relative flex-grow-1 d-none d-xl-flex px-4 align-items-center justify-content-start pt-xl-5",
          ].join(" ")}
        >
          {renderRightMenu()}
        </div>
      </div>
    </div>
  );
}

export default HomeHeader;
