import React from "react";
import PropTypes from "prop-types";

import PageBanner from "./PageBanner";
import PageSection from "./PageSection";
import FeaturedItems from "./FeaturedItems";

function PageContent(props) {
  const { pageSections } = props;
  const renderSection = (section, index) => {
    switch (section.fieldGroupName) {
      case "Template_AboutUs_Pagecontent_PageSection_BannerSection": // About Us
      case "Template_Blog_Pagecontent_PageSection_BannerSection": // Blog Archive
      case "Post_Pagecontent_PageSection_BannerSection": // Blog Post
        return <PageBanner key={index.toString()} data={section} />;
      case "Template_AboutUs_Pagecontent_PageSection_Section": // About Us
      case "Template_Blog_Pagecontent_PageSection_Section": // Blog Archive
      case "Post_Pagecontent_PageSection_Section": // Blog Post
        return <PageSection key={index.toString()} data={section} />;
      case "Template_Home_Homepagefields_Sections_FeaturedItems":
        return <FeaturedItems key={index.toString()} data={section} />;
      default:
        return null;
    }
  };
  const renderContent = () => {
    if (pageSections) {
      return pageSections.map((section, index) =>
        renderSection(section, index)
      );
    }
    return null;
  };

  return renderContent();
}
PageContent.propTypes = {
  pageSections: PropTypes.arrayOf(
    PropTypes.shape({
      fieldGroupName: PropTypes.string.isRequired,
    })
  ),
};
export default PageContent;
