// extracted by mini-css-extract-plugin
export var featuredItem = "FeaturedItems-module--featured-item--8a265";
export var featuredItems = "FeaturedItems-module--featured-items--49c86";
export var featuredItemsContainer = "FeaturedItems-module--featured-items-container--258da";
export var featuredItemsRow = "FeaturedItems-module--featured-items-row--828d5";
export var featuredItemsWrap = "FeaturedItems-module--featured-items-wrap--f73ce";
export var headingCurveLeft = "FeaturedItems-module--heading-curve-left--b52bd";
export var headingCurveRight = "FeaturedItems-module--heading-curve-right--c371a";
export var headingTriangle = "FeaturedItems-module--heading-triangle--a8d74";
export var headingWrap = "FeaturedItems-module--heading-wrap--ab9e9";
export var itemMedia = "FeaturedItems-module--item-media--7df4c";
export var itemTitle = "FeaturedItems-module--item-title--dbc43";
export var itemVideo = "FeaturedItems-module--item-video--75ce2";
export var triangleWrap = "FeaturedItems-module--triangle-wrap--c8134";